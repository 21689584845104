<template>
  <div class="softwareComponentVersionsSingleList">
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{ height: 'auto' }"
        :data-items="
          kgm_computedGridItems(softwareComponentVersionsSingles)
        "
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(softwareComponentVersionsSingles)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode,
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="componentNameTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${installationId}/software-components/${props.dataItem.componentId}`"
              class="alt-primary-color"
            >
              {{ kgm_getNestedValue(props.field, props.dataItem) }}
            </router-link>
          </td>
        </template>
        <div
          slot="textFilter"
          slot-scope="{ props, methods }"
          class="input-group"
        >
          <input
            ref="textFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Value"
            @input="
              (event) => {
                methods.change({
                  operator: 'contains',
                  field: props.field,
                  value: event.target.value,
                  syntheticEvent: event,
                });
              }
            "
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="
                kgm_resetInput('textFilterInput', (event) => {
                  methods.change({
                    operator: '',
                    field: '',
                    value: '',
                    syntheticEvent: event,
                  });
                })
              "
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <div class="btn-group-vertical">
              <div class="btn-group">
                <button
                  class="btn btn-primary btn-sm"
                  @click="showSidebarEdit(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="edit"
                  />
                </button>
                <button
                  class="btn btn-secondary btn-sm"
                  @click="openConfirmModal(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2 gray"
                    icon="trash"
                  />
                </button>
              </div>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <SoftwareComponentVersionSingleAdd
          v-if="addingElement"
          :installation-id="installationId"
          @reload="reloadSoftwareComponentVersionSingles()"
        />
        <SoftwareComponentVersionSingleEdit
          v-else
          :software-component-version-single="softwareComponentVersionsSingle"
          @reload="reloadSoftwareComponentVersionSingles()"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="softwareComponentVersionsSingle">
        {{
          $t("sureToDelete", {
            "0": softwareComponentVersionsSingle.componentName,
          })
        }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t("cancel") }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeSoftwareComponentVersionSingle()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t("delete") }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { SweetModal } from "sweet-modal-vue";
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import { authenticationMixin } from "@/mixins/authenticationMixin";
import Sidebar from "@/components/Base/Sidebar.vue";
import SoftwareComponentVersionSingleAdd from "@/components/SoftwareComponent/SoftwareComponentVersionSingleAdd";
import SoftwareComponentVersionSingleEdit from "@/components/SoftwareComponent/SoftwareComponentVersionSingleEdit";

export default {
  name: "SoftwareComponentVersionsSingleList",
  components: {
    SweetModal,
    Sidebar,
    SoftwareComponentVersionSingleAdd,
    SoftwareComponentVersionSingleEdit,
  },
  mixins: [kendoGridMixin, authenticationMixin],
  props: {
    installationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      softwareComponentVersionsSingle: null,
      softwareComponentVersionsSingles: null,
      showSidebar: false,
      addingElement: false,
      deleteIndex: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "key",
            operator: "contains",
            value: "",
          },
        ],
      },
      kgm_sort: [
        {
          field: "key",
          dir: "asc",
        },
      ],
      kgm_columns: [
        {
          field: "componentName",
          filterable: true,
          filter: "text",
          title: this.$t("name"),
          filterCell: "textFilter",
          cell: "componentNameTemplate",
        },
        {
          field: "version",
          filterable: true,
          filter: "text",
          title: this.$t("version"),
          filterCell: "textFilter",
        },
        {
          field: "description",
          filterable: true,
          filter: "text",
          title: this.$t("description"),
          filterCell: "textFilter",
        },
        {
          filterable: false,
          title: "Options",
          cell: "optionsTemplate",
          hidden: !(
            this.authenticationHasRole("scope_staff") &&
            (this.authenticationHasRole("secondlevel") ||
              this.authenticationHasRole("admin"))
          ),
          width: "190px",
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: this.$t("softwareComponent.softwareComponentVersions"),
    };
  },
  created() {
    this.getSoftwareComponentVersionsSingles();
  },
  methods: {
    //sidebar
    show() {
      this.showSidebar = true;
    },
    close() {
      this.showSidebar = false;
    },
    showAddSoftwareComponentVersionSingle() {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit(emitValues) {
      this.setCurrentSoftwareComponentVersionSingle(emitValues);
      this.addingElement = false;
      this.show();
    },
    setCurrentSoftwareComponentVersionSingle(softwareComponentVersionsSingle) {
      this.softwareComponentVersionsSingle = Object.assign(
        {},
        softwareComponentVersionsSingle
      );
    },
    //end sidebar

    //api

    openConfirmModal(data) {
      this.setCurrentSoftwareComponentVersionSingle(data)
      this.deleteIndex = data.id;
      this.$refs.confirmDelete.open();
    },
    reloadSoftwareComponentVersionSingles() {
      this.showSidebar = false;
      this.getSoftwareComponentVersionsSingles();
    },
    removeSoftwareComponentVersionSingle() {
      this.axios
        .delete(
          `/SoftwareComponent/DeleteSoftwareComponentVersionSingle?id=${this.deleteIndex}`
        )
        .then(() => {
          this.$snotify.success(
            this.$t("softwareComponent.versionDeletedSuccessfully")
          );
          this.getSoftwareComponentVersionsSingles();
        })
        .finally(() => {
          this.deleteIndex = null;
          this.$refs.confirmDelete.close();
        });
    },
    getSoftwareComponentVersionsSingles() {
      this.loading = true;
      this.$emit("startLoading", true);
      this.axios
        .get(
          `/SoftwareComponent/GetSoftwareComponentVersionSingles?installationId=${this.installationId}`
        )
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.softwareComponentVersionsSingles = response.data;
        })
        .finally(() => {
          this.loading = false;
          this.$emit("startLoading", false);
        });
    },
  },
};
</script>
<style>
.softwareComponentVersionsSingleList .k-grid table tr:hover td {
  background: rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>
