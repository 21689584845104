<template>
  <div class="softwareComponentVersionSingleEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Software Component Version
        </h4>
        <hr class="m-0 mb-3">
        <label>Version</label>
        <input
          v-model="softwareComponentVersionSingle.version"
          required
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('description') }}</label>
        <textarea
          ref="command"
          v-model="softwareComponentVersionSingle.description"
          required
          rows="3"
          class="form-control"
        />
        <hr class="m-0 mb-3">
        <button
          class="btn btn-primary float-right"
          :disabled="!softwareComponentVersionSingle.version"
          @click.prevent="editSoftwareComponentVersionSingle()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />
      </div>
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SoftwareComponentVersionSingleEdit",
  mixins: [
    errorMixin
  ],
  props: {
    softwareComponentVersionSingle: {
      type: Object,
      required: true
    }
  },
   data () {
    return {
      roles: ['admin', 'firstlevel', 'secondlevel']
    }
  },
  methods: {
    async editSoftwareComponentVersionSingle () {
      let postObject = {
        'id': this.softwareComponentVersionSingle.id,
        'version': this.softwareComponentVersionSingle.version,
        'description': this.softwareComponentVersionSingle.description,
        'installationId': this.installationId,
        'componentId': this.softwareComponentVersionSingle.componentId
      }
      
      await this.axios.put('/SoftwareComponent/UpdateSoftwareComponentVersionSingle', postObject)
        .then(() => {
          this.$snotify.success(this.$t('softwareComponent.versionUpdatedSuccessfully'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.softwareComponentCommandEdit .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>