<template>
  <div class="softwareComponentVersionSingleAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Software Component Version
        </h4>
        <hr class="m-0 mb-3">
        <label>Software Component</label>
        <LoadingPlaceholder v-if="loading" />
        <Multiselect
          v-else
          id="role"
          v-model="softwareComponent"            
          :options="softwareComponents"
          :close-on-select="true"
          class="mb-3"
          track-by="id"
          :searchable="true"
          label="name"
        />
        <hr class="m-0 mb-3">
        <label>Version</label>
        <input
          v-model="softwareComponentVersionSingle.version"
          required
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('description') }}</label>
        <textarea          
          v-model="softwareComponentVersionSingle.description"
          required
          rows="4"
          class="form-control"
        />
        <hr class="m-0 mb-3">
        <button
          class="btn btn-primary float-right"
          :disabled="!softwareComponentVersionSingle.version || !softwareComponent"
          @click.prevent="addSoftwareComponentVersionSingle()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('add') }}
        </button>
        <div class="clearfix" />
      </div>
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SoftwareComponentVersionSingleAdd",
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  mixins: [
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      softwareComponentVersionSingle: {},
      loading: false,
      softwareComponent: null,
      softwareComponents: []
    }
  },
  created() {
    this.getSoftwareComponents()
  },
  methods: {
    getSoftwareComponents() {
      this.loading = true;
      this.axios
        .get(
          `/SoftwareComponent/GetSoftwareComponentsForVersions?installationId=${this.installationId}`
        )
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.softwareComponents = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async addSoftwareComponentVersionSingle () {
      let postObject = {
        'version': this.softwareComponentVersionSingle.version,
        'description': this.softwareComponentVersionSingle.description,
        'installationId': this.installationId,
        'componentId': this.softwareComponent.id
      }
      
      await this.axios.post('/SoftwareComponent/AddSoftwareComponentVersionSingle', postObject)
        .then(() => {
          this.$snotify.success(this.$t('softwareComponent.versionAddedSuccessfully'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.softwareComponentVersionSingleAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>