var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"softwareComponentVersionsSingleList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({ height: 'auto' }),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.softwareComponentVersionsSingles),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.softwareComponentVersionsSingles),"sortable":{
        allowUnsort: _vm.kgm_allowUnsort,
        mode: _vm.kgm_sortMode,
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"componentNameTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('router-link',{staticClass:"alt-primary-color",attrs:{"to":("/installation/" + _vm.installationId + "/software-components/" + (props.dataItem.componentId))}},[_vm._v(" "+_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem))+" ")])],1)]}},{key:"textFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"textFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Value"},domProps:{"value":props.value},on:{"input":function (event) {
              methods.change({
                operator: 'contains',
                field: props.field,
                value: event.target.value,
                syntheticEvent: event,
              });
            }}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('textFilterInput', function (event) {
                methods.change({
                  operator: '',
                  field: '',
                  value: '',
                  syntheticEvent: event,
                });
              })}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"optionsTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticClass:"btn-group-vertical"},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.showSidebarEdit(props.dataItem)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"edit"}})],1),_c('button',{staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.openConfirmModal(props.dataItem)}}},[_c('font-awesome-icon',{staticClass:"mr-2 gray",attrs:{"icon":"trash"}})],1)])])])]}}])}),(_vm.showSidebar)?_c('Sidebar',{attrs:{"show-sidebar":_vm.showSidebar},on:{"close":_vm.close}},[(_vm.addingElement)?_c('SoftwareComponentVersionSingleAdd',{attrs:{"installation-id":_vm.installationId},on:{"reload":function($event){return _vm.reloadSoftwareComponentVersionSingles()}}}):_c('SoftwareComponentVersionSingleEdit',{attrs:{"software-component-version-single":_vm.softwareComponentVersionsSingle},on:{"reload":function($event){return _vm.reloadSoftwareComponentVersionSingles()}}})],1):_vm._e()],_c('SweetModal',{ref:"confirmDelete",staticClass:"overflowHidden",attrs:{"title":_vm.$t('introOutroListComp.deleteElement'),"icon":"warning","blocking":""}},[(_vm.softwareComponentVersionsSingle)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("sureToDelete", { "0": _vm.softwareComponentVersionsSingle.componentName, }))+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-secondary float-left mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.$refs.confirmDelete.close()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"times"}}),_vm._v(_vm._s(_vm.$t("cancel"))+" ")],1),_c('button',{staticClass:"btn btn-danger float-right mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.removeSoftwareComponentVersionSingle()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"trash"}}),_vm._v(_vm._s(_vm.$t("delete"))+" ")],1),_c('div',{staticClass:"clearfix"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }